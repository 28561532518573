import { EmailOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import axios from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import useHttpReducer from '../../hooks/useHttpReducer'
import useMediaQueries from '../../hooks/useMediaQueries'
import RDTextField from '../RDTextField/RDTextField'

type FormValues = {
  name: string
  email: string
  message: string
}

const ContactForm = ({ paddingTop = true }: { paddingTop?: boolean }) => {
  const initVals: FormValues = {
    name: '',
    email: '',
    message: '',
  }

  const { state, dispatch } = useHttpReducer()

  const { theme } = useMediaQueries()

  return (
    <Formik
      initialValues={initVals}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email not valid')
          .required('Email is required'),
        name: Yup.string().required('Required.'),
        message: Yup.string().required('Required.'),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatch({
          type: 'SENDING',
        })
        try {
          await axios.post('/api/contact', values)

          dispatch({
            type: 'SUCCESS',
          })

          setSubmitting(false)
          resetForm()
        } catch {
          dispatch({
            type: 'ERROR',
          })
        }
      }}
    >
      <Form
        style={{
          paddingTop: paddingTop ? '32px' : 0,
          maxWidth: '800px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Field name='name'>
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }: FieldProps) => (
            <Box pb={2}>
              <RDTextField
                required
                fullWidth
                disabled={state.loading}
                id='name'
                label='Name'
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={meta.touched && Boolean(meta.error)}
                touched={meta.touched}
                errorText={meta.touched && meta.error}
              />
            </Box>
          )}
        </Field>
        <Field name='email'>
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }: FieldProps) => (
            <Box pb={2}>
              <RDTextField
                disabled={state.loading}
                required
                fullWidth
                id='email'
                label='E-mail Address'
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                touched={meta.touched}
                onBlur={field.onBlur}
                error={meta.touched && Boolean(meta.error)}
                errorText={meta.touched && meta.error}
                helperText={`We'll use this email to respond to your message.`}
              />
            </Box>
          )}
        </Field>
        <Field name='message'>
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }: FieldProps) => (
            <Box pb={2}>
              <RDTextField
                disabled={state.loading}
                fullWidth
                multiline
                rows={5}
                maxRows={10}
                id='message'
                label='Message'
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                touched={meta.touched}
                error={meta.touched && Boolean(meta.error)}
                errorText={meta.touched && meta.error}
              />
            </Box>
          )}
        </Field>
        {state.error && (
          <Typography py='16px' variant='subtitle2' color='error'>
            An error occurred.
          </Typography>
        )}
        {state.success && (
          <Typography
            variant='subtitle2'
            py='16px'
            color={theme.palette.success.main}
          >
            Success! You will hear from us shortly!
          </Typography>
        )}
        <Button
          disabled={state.loading}
          variant='contained'
          color='primary'
          sx={{
            width: '100%',
            px: 3,
            py: 1,
          }}
          type='submit'
          endIcon={<EmailOutlined />}
        >
          Send Now
        </Button>
      </Form>
    </Formik>
  )
}

export default ContactForm
