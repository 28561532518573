import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { graphql, navigate } from 'gatsby'
import { KeyboardBackspace } from '@mui/icons-material'
import useMediaQueries from '../../hooks/useMediaQueries'
import dotGrid from '../../images/icons/dot_grid.svg'
import rLogo from '../../images/icons/small_linear_r.svg'
import serializers from '../../serializers/serializers'
import Layout from '../Layout/Layout'
import ContactForm from '../ContactForm/ContactForm'
import { sectionHeadingStyles } from '../CarouselBlock/CarouselBlock'
import SEO from '../SEO/SEO'

const PortfolioPage: React.FC<{
  // eslint-disable-next-line no-undef
  data: Queries.IndividualPortfolioPageQuery
}> = ({ data }) => {
  const { isSmall, theme } = useMediaQueries()

  return (
    <>
      <SEO
        title={data?.sanityPortfolioItem?.title || 'Services'}
        description={
          data?.sanityPortfolioItem?.content ||
          'A blog publishing helpful articles on frontend web development, Python, and other programming topics.'
        }
      />

      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <img
          src={dotGrid}
          style={{
            height: isSmall ? '0px' : '520px',
            width: '520px',
            position: 'absolute',
            top: 175,
            left: '0px',
            zIndex: 0,
          }}
          alt=''
        />
        <Box
          maxWidth='1200px'
          position='relative'
          mx='auto'
          my='14vh'
          p={isSmall ? 2 : 5}
          borderRadius={isSmall ? 0 : '18px'}
          sx={{
            background: 'white',
          }}
        >
          <Button
            startIcon={
              <KeyboardBackspace sx={{ color: theme.palette.secondary.main }} />
            }
            sx={{
              mb: 4,
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <article
            style={{
              paddingBottom: '64px',
            }}
            className='pt-3'
          >
            <BlockContent
              // eslint-disable-next-line no-underscore-dangle
              blocks={data?.sanityPortfolioItem?._rawBody}
              serializers={serializers}
            />
          </article>
          <img
            alt='logo'
            src={rLogo}
            style={{
              width: isSmall ? '100px' : '40vw',
              position: 'absolute',
              right: '0px',
              zIndex: 0,
              bottom: '0px',
              maxHeight: '50vh',
              opacity: 0.05,
            }}
          />
          <Box
            m='auto'
            maxWidth='1400px'
            py={15}
            px='15px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <Typography
              color='primary'
              borderBottom={`1px solid ${theme.palette.secondary.dark}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...sectionHeadingStyles(isSmall)}
            >
              Get In Touch!
            </Typography>
            <ContactForm />
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export const query = graphql`
  query IndividualPortfolioPage($header: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    sanityPortfolioItem(slug: { current: { eq: $header } }) {
      inProgress
      title
      content
      displayImage {
        asset {
          gatsbyImageData(height: 300, placeholder: BLURRED)
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default PortfolioPage
