import { Box, Typography } from '@mui/material'
import { SystemProps } from '@mui/system'
import React from 'react'
import useMediaQueries from '../../hooks/useMediaQueries'
import rLogo from '../../images/icons/small_linear_r.svg'

type CarouselBlockProps = {
  titleWord1?: string
  titleWord2?: string
  text?: React.ReactNode
  children?: React.ReactNode
  showRLogo?: boolean
}

export const sectionHeadingStyles = (isSmall: boolean): SystemProps => ({
  fontSize: isSmall ? '28px' : '55px',
  fontWeight: 700,
  lineHeight: '1.05',
  textTransform: 'uppercase',
})

const CarouselBlock: React.FC<CarouselBlockProps> = ({
  titleWord1,
  titleWord2,
  text,
  children,
  showRLogo,
}) => {
  const { isSmall } = useMediaQueries()

  return (
    <Box
      sx={{
        background:
          '-webkit-linear-gradient( -34deg, rgb(3,191,203) 0%, rgb(30,73,123) 100%)',
      }}
    >
      <Box maxWidth='1400px' m='auto' position='relative' zIndex={0} pb={10}>
        {showRLogo && (
          <img
            alt='logo'
            src={rLogo}
            style={{
              height: '25vw',
              position: 'absolute',
              left: '-10vw',
              zIndex: -1,
              bottom: '5vh',
            }}
          />
        )}
        <Box
          display='flex'
          flexWrap='wrap'
          pb={3}
          pt={8}
          justifyContent='center'
        >
          <Typography
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sectionHeadingStyles(isSmall)}
            color='white'
          >
            {titleWord1}&nbsp;
          </Typography>
          <Typography
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sectionHeadingStyles(isSmall)}
            color='white'
          >
            {titleWord2}
          </Typography>
        </Box>
        <Typography
          variant='body2'
          color='white'
          textAlign='center'
          maxWidth='1000px'
          m='auto'
          pt={4}
          px={2}
        >
          {text}
        </Typography>
        {children}
      </Box>
    </Box>
  )
}

export default CarouselBlock
